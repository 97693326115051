import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
export const Allprojects=()=>{
    const addTitle="Add Project";
    const editTitle="Edit Project";
    const params={_table_name:"projects",_condition:true};
    const columns = [
      { name: 'project_name', label: 'Name of project', type: 'text' },
      { name: 'start_date', label: 'Start Date', type: 'date' },
      { name: 'end_date', label: 'End Date', type: 'date' },
      { name: 'project_description', label: 'Project Description', type: 'textarea' },
      { name: 'project_manager', label: 'Project Manager', type: 'text'},
      { name: 'status', label: 'Status', type: 'text' },
      { name: 'created_at', label: 'Created On', type: 'date' },
      { name: 'updated_at', label: 'Password', type: 'date' },
     
     
     
    ];
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }