import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
export const Allsteeringcommitteemembers=()=>{
    const addTitle="Add Steering Committee Member";
    const editTitle="Edit Steering Committee Member";
    const params={_table_name:"steering_committee_members",_condition:true};
    const columns = [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'surname', label: 'Surname', type: 'text' },
      { name: 'organization_name', label: 'Organization Name', type: 'text' },
      { name: 'email', label: 'Email', type: 'email' },
      { name: 'telephone_no', label: 'Telephone Number', type: 'text' },
      { name: 'contribution_type', label: 'Contribution Type', type: 'dropdown',options:["Cash","In Kind","Strategic"] },
      { name: 'contribution_amount',dependsOn:"contribution_type",ifIs:"Cash",label: 'Amount Contributed(ZAR)', type: 'text' },
      { name: 'in_kind_description',dependsOn:"type_of_funding",ifIs:"In Kind",label: 'In Kind Description', type: 'textarea' },
      { name: 'strategic_description',dependsOn:"contribution_type",ifIs:"Strategic",label: 'Strategic Description', type: 'textarea' },
      { name: 'other_contributions', label: 'Other Contributions', type: 'textarea' },
    ];
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }