import { CRUD } from "../CRUD";
import { generalApiEndpoint } from "../QUICKFUNCTIONS/endpoint";
import { useNavigate } from "react-router-dom";
export const Allportfolioreports=()=>{
  const navigate = useNavigate();

  const handleExportToCSV = (rowData) => {
    localStorage.removeItem("word_data");
    localStorage.setItem("word_data",JSON.stringify(rowData));

    // Navigate to the WordDocumentGenerator component with rowData as a prop
    navigate('../worddoc');
  };
    const addTitle="Add Portfolio Report";
    const editTitle="Edit Report Info";
    const params={_table_name:"portfolioreports",_condition:true};
    const columns = [
      { name: 'implementing_entity',label:"Implementing Entity", type: 'text',placeholder:"Implementing Entity"},
      { name: 'contact_person', label: 'Contact Person', type: 'text',placeholder:"Enter Name" },
      { name: 'contact_number', label: 'Contact Number', type: 'text',placeholder:"Enter Number"  },
      { name: 'email_address', label: 'Email Address', type: 'email',placeholder:"Enter Email" },
      { name: 'province', label: 'Province', type: 'dropdown', options: ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'] },
      { name: 'district',label: 'District', type: 'dropdown',options:[],type: 'dropdown',trigger:"province",fetchurl:generalApiEndpoint+"/getdistricts.php" },
      { name: 'municipality', label: 'Municipality', type: 'dropdown',options:[],trigger:"district",fetchurl:generalApiEndpoint+"/getmunicipalities.php" },
      { name: 'gps_co_ordinates', label: 'GPS Co-ordinates', type: 'text'},
      { name: 'platform_description', label: 'Platform Description', type: 'textarea'},
      { name: 'status_update_per_intervention', label: 'Status Update Per Intervention', type: 'textarea',placeholder:"Describe" },
      { name: 'challenges_encountered', label: 'Challenges Encountered', type: 'textarea' },
      { name: 'description_of_mitigation_of_action', label: 'Description Of Mitigation Of Action', type: 'textarea' }
    ];
     const actions = [
      {
        name: "export_csv",
        label: 'View Report',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: 'green', cursor: 'pointer' }} onClick={handleExportToCSV(rowData)}>
          View Report
        </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }