// InputText.js
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const InputText = ({ column, formData, handleChange }) => {
  if(column.value){formData[column.name] = column.value;}
  return (
  
  <FormGroup>
    <Label for={column.name}>{column.label || column.name}</Label>
    <Input
      type="text"
      placeholder={column.placeholder ? column.placeholder : ""}
      id={column.name}
      name={column.name}
      value={formData[column.name] || ''}
      onChange={(e) => handleChange(e, column.name)}
    />
  </FormGroup>
);
}

export default InputText;
