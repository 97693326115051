import { useEffect, useState } from "react";
import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
import { fetchData } from "../QUICKFUNCTIONS/fetchData";
import { generalApiEndpoint } from "../QUICKFUNCTIONS/endpoint";

export const Allbeneficiaries=()=>{
    const [mymunic,setMymunic]=useState([]);
    const fetchDataAndSetState = async () => {
      try {
        const data = await fetchData(generalApiEndpoint + "/getmymunic.php?token=" + localStorage.getItem("token"));
        setMymunic(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      fetchDataAndSetState();
    }, []); // The empty dependency array ensures that this effect runs only once after the initial render
  
    
     
    const addTitle="Load beneficiary info";
    const editTitle="Edit Beneficiary Info";
    const params={_table_name:"beneficiary_database",_condition:true};
    const columns = [
       { name: 'beneficiary_type', label: 'Type Of Beneficiary', type: 'radio',options:["SMME","Innovator"] },
       { name: 'name', label: 'Name', type: 'text' },
       
       { name: 'surname', label: 'Surname',dependsOn:"beneficiary_type",ifIs:"Innovator", type: 'text' },
       { name: 'business_registration', label: 'Business Registration',dependsOn:"beneficiary_type",ifIs:"SMME", type: 'text' },
       { name: 'id_type', label: 'ID Type',dependsOn:"beneficiary_type",ifIs:"Innovator", type: 'dropdown',options:["RSA ID","Passport"] },
       { name: 'passport', label: 'Passport Number',dependsOn:"id_type",ifIs:"Passport", type: 'text' },
       { name: 'said', label: 'ID Number',dependsOn:"id_type",ifIs:"RSA ID", type: 'text' },
       { name: 'email', label: 'Email', type: 'email',placeholder:"Enter email" }, 
       { name: 'race', label: 'Race',dependsOn:"beneficiary_type",ifIs:"Innovator", type: 'dropdown',options:['Coloured', 'Indian', 'African', 'White', 'Chinese', 'Foreign African', 'Foreign Other', 'Other'] },
       { name: 'race_description', label: 'Specify Race', type: 'textarea',dependsOn:"race",ifIs:"Other" },  
       { name: 'gender', label: 'Gender',dependsOn:"beneficiary_type",ifIs:"Innovator", type: 'dropdown',options:["Male","Female","Other"] },
       { name: 'nature_of_business', label: 'Nature Of Business', type: 'text'},
       { name: 'idea', label: 'Idea', type: 'text'},
       { name: 'interventions_category', label: 'Interventions Category', type: 'dropdown',options:["Training","Innovation Challenges","Information Sharing","Workshop & Networking Sessions","Mentorship Programme"] },
       { name: 'activities_description', label: 'Activities (Description)', type: 'textarea' },
       { name: 'innovation', label: 'Innovation', type: 'text' },
       { name: 'website', label: 'Website', type: 'text' },
       {name:'municipality_id',label:'Municipality Id',type:"text",value:`${mymunic && mymunic[0]}`}
     ];
     const dataPoint="beneficiary_type";
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD dataPoint={dataPoint} actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }