// TableHeaderCell.js
import React from 'react';
import { Input, Label } from 'reactstrap';

const TableHeaderCell = ({ header, columnFilters,formatHeader, handleColumnFilterChange, getDistinctColumnValues, clickedColumn, handleColumnClick }) => (
  <th
    style={{
      border: '.1px solid #ccc',
      backgroundColor: clickedColumn === header ? 'rgba(0,255,0,0.05)' : 'inherit',
    }}
    onClick={() => handleColumnClick(header)}
  >
    <div>
      <Label for={`filter-${header}`}>{formatHeader(header)}</Label>
      <Input
        type="select"
        id={`filter-${header}`}
        value={columnFilters[header] || ''}
        onChange={(e) => handleColumnFilterChange(header, e.target.value)}
        style={{ border: 'none', padding: '0px', width: '200px' }}
      >
        <option value="">-- All --</option>
        {getDistinctColumnValues(header).map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </Input>
    </div>
  </th>
);

export default TableHeaderCell;
