import LandingPage from "views/landingpage";
import { Allbeneficiaries } from "views/QUICKUI/QUICKCOMPONENTS/AllBeneficiaries";
import { Allsteeringcommitteemembers } from "views/QUICKUI/QUICKCOMPONENTS/Allsteeringcommitteemembers";
import { Allcapacitybuildingprogrammes } from "views/QUICKUI/QUICKCOMPONENTS/Allcapacitybuildingprogrammes";
import { Allportfolioreports } from "views/QUICKUI/QUICKCOMPONENTS/Allportfolioreports";
import { Allorganizations } from "views/QUICKUI/QUICKCOMPONENTS/Allorganizations";
import { Allmentors } from "views/QUICKUI/QUICKCOMPONENTS/Allmentors";
import { Allinnovationserviceproviders } from "views/QUICKUI/QUICKCOMPONENTS/Allinnovationserviceproviders";
import { Allsmmefundings } from "views/QUICKUI/QUICKCOMPONENTS/Allsmmefundings";
import { Allprojects } from "views/QUICKUI/QUICKCOMPONENTS/Allprojects";
import { Allprojectstakeholders } from "views/QUICKUI/QUICKCOMPONENTS/Allprojectstakeholders";

var routes = [
  {
    path: "/landingpage",
    name: "Home",
    rtlName: "Home",
    icon: "",
    component: <LandingPage/>,
    layout: "/admin",
  },
  {
    path: "/portfolioreports",
    name: "Portfolio Reports",
    icon: "",
    component: <Allportfolioreports/>,
    layout: "/admin",
  },
  {
    path: "/organizations",
    name: "STI Organizations",
    rtlName: "Organizations",
    icon: "",
    component: <Allorganizations />,
    layout: "/admin",
  }
,
{
  path: "/beneficiaries",
  name: "Beneficiaries",
  icon: "",
  component: <Allbeneficiaries />,
  layout: "/admin",
}
  
  ,
  {
    path: "/mentors",
    name: "Mentors",
    icon: "",
    component: <Allmentors />,
    layout: "/admin",
  } ,
  {
    path: "/steeringcommitteemembers",
    name: "Steering Committee Members",
    icon: "",
    component: <Allsteeringcommitteemembers/>,
    layout: "/admin",
  }

,{
  path: "/innovationserviceproviders",
  name: "Innovation Service Providers",
  icon: "",
  component: <Allinnovationserviceproviders/>,
  layout: "/admin",
},
{
  path: "/smmefundings",
  name: "SMMEs That Secured Funding",
  icon: "",
  component: <Allsmmefundings/>,
  layout: "/admin",
},
{
  path: "/projectstakeholders",
  name: "Project Stakeholders",
  icon: "",
  component: <Allprojectstakeholders/>,
  layout: "/admin",
}
,
{
  path: "/capacitybuildingprogrammes",
  name: "Capacity Building Programmes",
  icon: "",
  component: <Allcapacitybuildingprogrammes/>,
  layout: "/admin",
},
{
  path: "/projects",
  name: "Projects",
  icon: "",
  component: <Allprojects/>,
  layout: "/admin",
}
];

export default routes;
