import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
export const Allsmmefundings=()=>{
    const addTitle="Add Further Funding Secured";
    const editTitle="Edit Further Funding Secured";
    const params={_table_name:"smmefunding",_condition:true};
    const columns = 
          [
            { name: 'smme_innovator', label: 'SMME/Innovator', type: 'text' },
            { name: 'idea', label: 'Idea', type: 'text' },
            { name: 'source_of_funding', label: 'Source of Funding', type: 'text' },
            { name: 'total_funding_secured', label: 'Total Funding Secured', type: 'text' },
          ];
      
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }