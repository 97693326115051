// GlobalSearchInput.js
import React from 'react';
import { Input } from 'reactstrap';

const GlobalSearchInput = ({ searchValue, setSearchValue }) => (
  <Input
    type="text"
    placeholder="Global Search..."
    value={searchValue}
    onChange={(e) => setSearchValue(e.target.value)}
    style={{
      marginTop: '15px',
      border: 'none',
      borderBottom: '.1px dashed darkorange',
      borderRadius: '0px',
    }}
  />
);

export default GlobalSearchInput;
