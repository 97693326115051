
const separateEach = (array, delim) => {
    var arr = [];
    for (var i = 0; i < array.length; i++) {
      if (i === (arr.length - 1)) {
        arr.push(array[i]);
      } else {
        arr.push(array[i]);
      }
    }
    return arr;
  }
  
 export function handleExportToCSV(rowData, columns) {
    const dataValues = Object.values(rowData);
    let columnLabels = Object.keys(rowData);
    const timestamp = new Date().toISOString().replace(/[-:]/g, "");
    const fileName = `exported_data_${timestamp}.csv`;
  
    // Uppercase the headers
    columnLabels = columnLabels.map(header => header.toUpperCase());
  
    const csvContent = "data:text/csv;charset=utf-8," +
      separateEach(columnLabels, ",") + "\n" +
      separateEach(dataValues.flat(), ",") + "\n";
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
  