import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
export const Allcapacitybuildingprogrammes=()=>{
    const addTitle="Add Capacity Buinding Programme";
    const editTitle="Edit Capacity Buinding Programme";
    const params={_table_name:"capacitybuildingprogrammes",_condition:true};
    const columns = [
      { name: 'Category', label: 'Category', type: 'dropdown', options: ['Training', 'Innovation Challenges', 'Information Sharing', 'Workshop & Networking Sessions', 'Mentorship Programme'] },
      { name: 'Intervention', label: 'Interventions', type: 'textarea', condition: (formData) => formData.Category === 'Innovation Challenges' || formData.Category === 'Mentorship Programme' },
    ];
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }