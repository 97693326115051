// TableRow.js
import React from 'react';
import TrashCan from '../Icons/TrashCan';
import EditIcon from '../Icons/EditIcon';
import MenuWrapper from './MenuWrapper';
import Notiflix from "../../../../node_modules/notiflix/src/notiflix";

const TableRow = ({ canDelete,canEdit,item,actions,staticColumns,clickedColumn, rowIndex,setHighlightedRow, clickedRow, highlightedRow, handleDeleteClick, handleEditClick, handleCustomActionClick, handleArrowClick }) => (
  <tr
    style={{
      backgroundColor:
        clickedRow === rowIndex
          ? 'rgba(0, 0, 240, 0.05)' // Yellow shade for click
          : highlightedRow === rowIndex
          ? 'rgba(255, 255, 100, 0.1)' // Light highlight on hover
          : 'inherit',
    }}
    onMouseEnter={() => setHighlightedRow(rowIndex)}
    onMouseLeave={() => setHighlightedRow(null)}
    onClick={() => handleArrowClick(rowIndex)}
  >
  <td style={{ textAlign: 'center' }}>
      <table style={{ border: "none" }}>
        <tr style={{ border: "none" }}>
        {canDelete == true && <td style={{ verticalAlign: "center", border: "none" }}>
            <span onClick={(e) =>{ 
              handleDeleteClick(item, rowIndex)}}>
              <TrashCan />
            </span>
          </td>
          }
        {canEdit == true ? <>
          <td style={{ verticalAlign: "center", border: "none" }}>
            <span onClick={(e) => handleEditClick(item, rowIndex)}>
              <EditIcon />
            </span>
          </td></>:<></>}
          <td style={{ verticalAlign: "center", border: "none" }}>
            <MenuWrapper actions={actions} columns={staticColumns} row={item} />
          </td>
        </tr>
      </table>
    </td>
    {Object.values(item).map((value, columnIndex) => (
      <td
        key={columnIndex}
        style={{
          cursor: 'pointer',
          backgroundColor: clickedColumn === columnIndex ? 'rgba(0,255,0,0.05)' : 'inherit',
        }}
      >
        {value}
      </td>
    ))}
  </tr>
);

export default TableRow;
