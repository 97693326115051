import React, { useEffect, useRef, useState } from 'react';

const SimplePieChart = ({ data,labels}) => {
  const canvasRef = useRef(null);
  const colors=["orange","steelblue","grey","green","cyan","pink","navy","brown","indigo","purple","violet"];
  

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const drawPieChart = () => {
      const total = data.reduce((acc, value) => acc + value, 0);
      let currentAngle = -0.5 * Math.PI;

      data.forEach((value, index) => {
        const sliceAngle = (2 * Math.PI * value) / total;

        // Draw the slice
        context.beginPath();
        context.moveTo(canvas.width / 2, canvas.height / 2);
        context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 3, currentAngle, currentAngle + sliceAngle);
        context.fillStyle = colors[index];
        context.fill();

        currentAngle += sliceAngle;
      });
    };

    drawPieChart();
  }, [data, colors]);

  const generateList = () => {
    const total = data.reduce((acc, value) => acc + value, 0);

    return data.map((value, index) => (
      <li style={{ listStyleType: "none", display: "inline-block", paddingLeft: "15px" }} key={index}>
        <table><tr><td><button style={{ width: "8px", height: "8px", color: colors[index], background: colors[index], borderRadius: "0%", border: "none", justifyContent: "center", alignItems: "center", display: "flex", float: "left" }}></button></td><td><label style={{ marginLeft: "5px", fontSize: "11px", marginBottom: "4px" }}>{labels[index]} {Math.round((value / total) * 100)}%</label></td></tr></table>
      </li>
    ));
  };

  return (
    <div style={{ display: 'flex' }}>
      <table><tr><td><canvas ref={canvasRef} width={110} height={110} style={{ marginRight: '-20px', marginTop: "-20px" }} /></td><td><ul style={{ padding: "0px" }}>{generateList()}</ul></td></tr></table>
    </div>
  );
};

export default SimplePieChart;
