import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
import { generalApiEndpoint } from "../QUICKFUNCTIONS/endpoint";
export const Allinnovationserviceproviders=()=>{
    const addTitle="Add Innovation Service Provider";
    const editTitle="Edit Innovation Service Provider";
    const params={_table_name:"innovation_service_providers",_condition:true};
    const columns = [
      {name:"organization_name", label: 'Organization Name', type: 'text' },
      {name:"email", label: 'Email', type: 'email' },
      {name:"telephone_no", label: 'Telephone Number', type: 'text' },
      { name: 'province', label: 'Province', type: 'dropdown', options: ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'] },
      { name: 'district',label: 'District', type: 'dropdown',options:[],type: 'dropdown',trigger:"province",fetchurl:generalApiEndpoint+"/getdistricts.php" },
      { name: 'municipality', label: 'Municipality', type: 'dropdown',options:[],trigger:"district",fetchurl:generalApiEndpoint+"/getmunicipalities.php" },
      {name:"areas_of_expertise",label: 'Areas of Expertise',type: 'text'}];
      const dataPoint="province";
      const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD dataPoint={dataPoint} actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }