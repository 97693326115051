import { createContext } from "react";

export const backgroundColors = {
  primary: "primary",
  blue: "#001d34",
  green: "green",
  orange:"#df5e11e6"
};

export const BackgroundColorContext = createContext();
