import React from "react";
import bg from "assets/img/bg.png";
import departmentlogo from "assets/img/department.png";
import "./header.css";

const Header=()=>{
    return(
        <div style={{height:"300px",backgroundSize:"cover",position:"relative",borderRadius:"10px 10px 0 0",backgroundPosition:"center",borderBottom:"20px solid #001d34",backgroundImage:`URL(${bg})`}}>
            <br/>
            <h1 className='text-left header-text' style={{maxWidth:"300px",margin:"15px",float:"left"}}>Regional Innovation Support Programme</h1>
            <img src={departmentlogo} className="org-logo" style={{position:"absolute",zIndex:"99",top:"20px",right:"20px",width:"200px"}}/>
        </div>
    );
};

export default Header;