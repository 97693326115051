import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
import { generalApiEndpoint } from "../QUICKFUNCTIONS/endpoint";
export const Allorganizations=()=>{
    const addTitle="Add Organization";
    const editTitle="Edit Organization";
    const params={_table_name:"sti_organizations",_condition:true};
    const columns = [
      { name: 'entitytype',label:"Entity Type", options:['National Entity','Regional Entity'], type: 'radio'},
      { name: 'organization_name', label: 'Organization Name', type: 'text' },
      { name: 'email', label: 'Email', type: 'email' },
      { name: 'telephone_no', label: 'Telephone Number', type: 'text' },
      { name: 'province',dependsOn:"entitytype",ifIs:"Regional Entity", label: 'Province', type: 'dropdown', options: ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'] },
      { name: 'district',dependsOn:"entitytype",ifIs:"Regional Entity",label: 'District', type: 'dropdown',options:[],type: 'dropdown',trigger:"province",fetchurl:generalApiEndpoint+"/getdistricts.php" },
      { name: 'municipality',dependsOn:"entitytype",ifIs:"Regional Entity", label: 'Municipality', type: 'dropdown',options:[],trigger:"district",fetchurl:generalApiEndpoint+"/getmunicipalities.php" },
      { name: 'areas_of_expertise', label: 'Areas of Expertise', type: 'textarea' },
      { name: 'contribution_type', label: 'Contribution Type', type: 'dropdown',options:["Cash","In Kind","Strategic"] },
      { name: 'contribution_amount',dependsOn:"contribution_type",ifIs:"Cash",label: 'Amount Contributed(ZAR)', type: 'text' },
      { name: 'in_kind_description',dependsOn:"type_of_funding",ifIs:"In Kind",label: 'In Kind Description', type: 'textarea' },
      { name: 'strategic_description',dependsOn:"contribution_type",ifIs:"Strategic",label: 'Strategic Description', type: 'textarea' },
      { name: 'other_contributions', label: 'Other Contributions', type: 'textarea' },
    ];
     const dataPoint="entitytype";
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD dataPoint={dataPoint} actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }