import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ThreeBars from '../../ThreeBars';
import "./MenuWrapper.css";

const MenuWrapper = ({ actions, columns, row }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    setMenuVisible(!menuVisible);
  };

  const handleMenuItemClick = (e, action) => {
    e.stopPropagation();
    action.render(row, columns);
    setMenuVisible(false);
  };

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Empty dependency array means this effect will run once on mount and clean up on unmount

  return (
    <div className="three-dot-menu" onClick={handleMenuClick} ref={menuRef}>
      <ThreeBars />

      {menuVisible && (
        <div style={{ position: 'absolute', background: 'white', padding: '5px', zIndex: '99999999' }} className="menu-items">
          {actions &&
            actions.map((action, index) => (
              <div
                style={{ margin: '5px', fontSize: '11px', cursor: 'pointer' }}
                key={index}
                className="menu-item"
                onClick={(e) => handleMenuItemClick(e, action)}
              >
                {action.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

MenuWrapper.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    })
  ),
};

export default MenuWrapper;
