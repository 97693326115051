// InputText.js
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const InputEmail = ({ column, formData, handleChange }) => (
  <FormGroup>
    <Label for={column.name}>{column.label || column.name}</Label>
    <Input
      type="email"
      id={column.name}
      name={column.name}
      placeholder={column.placeholder ? column.placeholder : ""}
      value={formData[column.name] || ''}
      onChange={(e) => handleChange(e, column.name)}
    />
  </FormGroup>
);

export default InputEmail;
