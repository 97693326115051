import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom"; // Import HashRouter instead of BrowserRouter
import AdminLayout from "layouts/Admin/Admin.js";
import "assets/scss/black-dashboard-react.scss";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import Login from "views/Login";
import WordDocumentGenerator from "views/CreateDoc";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <HashRouter> {/* Use HashRouter instead of BrowserRouter */}
        <Routes>
          <Route path="/docjs" element={<WordDocumentGenerator/>}/>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/" element={<Login />} />
          <Route
            path="*"
            element={<Navigate to="/admin/landingpage" replace />}
          />
        </Routes>
      </HashRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
