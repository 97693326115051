import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
import React, { useState } from 'react';
import { generalApiEndpoint } from "../QUICKFUNCTIONS/endpoint";

export const Alladministrators = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMunicipalityId, setSelectedMunicipalityId] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [assignedMunicipalities, setAssignedMunicipalities] = useState([]);
  const [showAssignedModal, setShowAssignedModal] = useState(false);

  const addTitle = "Add Administrator";
  const editTitle = "Edit Administrator";
  const params = { _table_name: "adminusers", _condition: true };
  const columns = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'surname', label: 'Surname', type: 'text' },
    { name: 'useremail', label: 'Email', type: 'email' },
    { name: 'userpassword', label: 'Password', type: 'text' },
    { name: 'admintype', label: 'Admin Type', type: 'dropdown', options: ["Local", "Global"] }
  ];

  const actions = [
    {
      name: "export_csv",
      label: 'Export to CSV',
      type: 'custom',
      render: (rowData, columns) => (
        <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData, columns)}>
          Export to CSV
        </span>
      )
    },
    {
      name: "assign_municipality",
      label: 'Assign to municipality',
      type: 'custom',
      render: (rowData, columns) => (
        <span style={{ color: "green", cursor: "pointer" }} onClick={handleAssignMunicipality(rowData.id)}>
          Assign to municipality
        </span>
      )
    },
    {
      name: "view_assigned_municipalities",
      label: 'View Assigned Municipalities',
      type: 'custom',
      render: (rowData, columns) => (
        <span style={{ color: "blue", cursor: "pointer" }} onClick={handleViewAssignedMunicipalities(rowData.id)}>
          View Assigned Municipalities
        </span>
      )
    }
  ];

  const handleSearch = async () => {
    try {
      const response = await fetch(`${generalApiEndpoint}/searchmunicipality.php?query=${searchQuery}`);
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error searching municipality:", error);
    }
  };

  const handleAssignMunicipality = (adminId) => {
    setSelectedAdminId(adminId);
    setShowSearchPopup(true);
  };

  const handleAssign = async (selectedMunic) => {
    try {
      const response = await fetch(`${generalApiEndpoint}/assignadmin.php?adminId=${selectedAdminId}&municId=${selectedMunic}`);
      const data = await response.json();
      if (data.success) {
        alert(data.success);
      } else {
        if (data.error) {
          alert(data.error);
        }
      }
      setShowSearchPopup(false);
    } catch (error) {
      console.error("Error assigning municipality:", error);
    }
  };

  const handleViewAssignedMunicipalities = async (adminId) => {
    try {
      const response = await fetch(`${generalApiEndpoint}/viewassignedmunicipalities.php?adminId=${adminId}`);
      const data = await response.json();
      setSelectedAdminId(adminId);
      setAssignedMunicipalities(data);
      setShowAssignedModal(true);
    } catch (error) {
      console.error("Error viewing assigned municipalities:", error);
    }
  };

  const handleUnassignMunicipality = async (municipalityId) => {
    try {
      const response = await fetch(`${generalApiEndpoint}/unassignmunicipality.php?adminId=${selectedAdminId}&assignmentId=${municipalityId}`);
      const data = await response.json();
      if (data.success) {
        alert(data.success);
        // Refresh the assigned municipalities after unassigning
        handleViewAssignedMunicipalities(selectedAdminId);
      } else {
        if (data.error) {
          alert(data.error);
        }
      }
    } catch (error) {
      console.error("Error unassigning municipality:", error);
    }
  };

  return (
    <>
      <CRUD actions={actions} canDelete={true} canEdit={false} params={params} addTitle={addTitle} editTitle={editTitle} columns={columns} />
      {showSearchPopup && (
        <div style={{ background: "#f5f6fa", position: "fixed", top: "0px", left: "0px", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "9999999999" }}>
          <div style={{ borderRadius: "10px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)", width: "400px", maxWidth: "98vw", background: "white", position: "relative", padding: "50px" }}>
            <h3 className="text-center">Search Municipality</h3>
            <input className="form-input form-control" type="search" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); handleSearch(); }} />
            <div>
              {searchResults.map((result) => (
                <p key={result.id} onClick={() => { setSelectedMunicipalityId(result.id); handleAssign(result.id); }}>{result.municipality}</p>
              ))}
            </div>
            <button style={{ position: "absolute", top: "5px", right: "5px", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", background: "transparent", border: ".1px solid #ccc", borderRadius: "50%", zIndex: "999" }} onClick={() => setShowSearchPopup(false)}>&times;</button>
          </div>
        </div>
      )}
      {showAssignedModal && (
        <div style={{ background: "rgba(0, 0, 0, 0.5)", position: "fixed", top: "0px", left: "0px", width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "9999999999" }}>
          <div style={{ borderRadius: "10px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)", width: "400px", maxWidth: "98vw", background: "white", position: "relative", padding: "20px" }}>
            <h3 className="text-center">Assigned Municipalities</h3>
            <ul style={{padding:"0px"}}>
              {assignedMunicipalities.map((municipality) => (
                <li style={{listStyleType:"none",padding:"10px"}} key={municipality.id}>
                  {municipality.municipality}
                  <span style={{ fontWeight:"400",color: "red", cursor: "pointer", marginLeft: "10px" }} onClick={() => handleUnassignMunicipality(municipality.id)}>
                    Unassign
                  </span>
                </li>
              ))}
            </ul>
            <button style={{ position: "absolute", top: "5px", right: "5px", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", background: "transparent", border: ".1px solid #ccc", borderRadius: "50%", zIndex: "999" }} onClick={() => setShowAssignedModal(false)}>&times;</button>
          </div>
        </div>
      )}
    </>
  );
};
