import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import FileUploaderIcon from '../Icons/FileUploaderIcon';

const InputPhoto = ({ column, chosenImagesMap, handleChange }) => (
  <center style={{ border: '.1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
    <br />
    <Label for={column.name}><FileUploaderIcon/> {column.label || column.name}</Label>
    <Input
     style={{visibility:"hidden"}}
      type="file"
      id={column.name}
      name={column.name}
      onChange={(e) => handleChange(e, column.name)}
      multiple // Allow multiple file selection
    />
    {chosenImagesMap[column.name] && (
      <div>
        <p>Chosen Images:</p>
        <ul style={{ padding: '0px' }}>
          {chosenImagesMap[column.name].map((image, index) => (
            <li style={{ listStyleType: 'none' }} key={index}>
              <img
                src={URL.createObjectURL(image)}
                alt={`Chosen ${index}`}
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            </li>
          ))}
        </ul>
      </div>
    )}
  </center>
);

export default InputPhoto;
