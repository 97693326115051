// CapacityIcon.jsx

import React from 'react';

const CapacityIcon = () => {
  return (
    <div style={{ width: '100px', height: '100px' }}>
      <svg
        fill="#000000"
        height="100%"
        width="100%"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512.004 512.004"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M136.535,230.403H68.269c-4.71,0-8.533,3.814-8.533,8.533v204.8c0,4.719,3.823,8.533,8.533,8.533h68.267
                c4.71,0,8.533-3.814,8.533-8.533v-204.8C145.069,234.218,141.246,230.403,136.535,230.403z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M238.935,153.97h-68.267c-4.514,0-8.175,3.661-8.175,8.166v281.6c0,4.506,3.661,8.166,8.175,8.166h68.267
                c4.514,0,8.175-3.661,8.175-8.166v-281.6C247.11,157.631,243.45,153.97,238.935,153.97z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M341.336,196.27h-68.267c-4.71,0-8.533,3.814-8.533,8.533v238.933c0,4.719,3.823,8.533,8.533,8.533h68.267
                c4.71,0,8.533-3.814,8.533-8.533V204.803C349.869,200.084,346.046,196.27,341.336,196.27z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M443.735,110.937h-68.267c-4.71,0-8.533,3.814-8.533,8.533v324.267c0,4.719,3.823,8.533,8.533,8.533h68.267
                c4.71,0,8.533-3.814,8.533-8.533V119.47C452.269,114.751,448.446,110.937,443.735,110.937z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M409.602,25.603h-51.2c-4.71,0-8.533,3.814-8.533,8.533s3.823,8.533,8.533,8.533h29.184l-81.826,66.961l-97.673-40.704
                c-2.79-1.161-5.99-0.759-8.405,1.05l-102.4,76.8c-3.772,2.825-4.531,8.175-1.707,11.938c1.672,2.244,4.241,3.422,6.835,3.422
                c1.775,0,3.575-0.555,5.111-1.707l98.5-73.873l97.894,40.789c2.918,1.229,6.255,0.725,8.687-1.263l88.465-72.38v23.1
                c0,4.719,3.823,8.533,8.533,8.533s8.533-3.814,8.533-8.533V34.137C418.135,29.418,414.313,25.603,409.602,25.603z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M509.502,471.837l-25.6-25.6c-3.337-3.337-8.73-3.337-12.066,0c-3.337,3.336-3.337,8.73,0,12.066l11.034,11.034H42.669
                V29.136L53.702,40.17c1.664,1.664,3.849,2.5,6.033,2.5s4.369-0.836,6.033-2.5c3.336-3.336,3.336-8.73,0-12.066l-25.6-25.6
                c-3.336-3.336-8.73-3.336-12.066,0l-25.6,25.6c-3.336,3.337-3.336,8.73,0,12.066c3.337,3.337,8.73,3.337,12.066,0l11.034-11.034
                V477.87c0,4.719,3.823,8.533,8.533,8.533h448.734l-11.034,11.034c-3.337,3.337-3.337,8.73,0,12.066
                c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5l25.6-25.6C512.838,480.566,512.838,475.173,509.502,471.837z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CapacityIcon;
