import React from 'react';
import Header from './Header';

const WordDocumentGenerator = () => {
  const data=JSON.parse(localStorage.getItem("word_data"));
  //alert(JSON.stringify(data));
  const downloadDocument = () => {
    // Get the content from the contenteditable div
    const content = document.getElementById('document-content').innerHTML;

    // Create a Blob with the HTML content
    const blob = new Blob([`<html><body>${content}</body></html>`], {
      type: 'application/msword',
    });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'portfolioreport.doc';
    link.click();
  };

  return (
    <div className='content'>
      <Header/>
      <div
        id="document-content"
        contentEditable
        style={{ border: '1px solid #ccc', minHeight: '200px', padding: '10px' }}
      >
        <table className='table'>
        <tr>
            <th style={{background:"lightgrey",textAlign:"center"}} colspan="4">REPORT ON SOUTH AFRICA'S REGIONAL INNOVATION SUPPORT PLATFORMS</th>
          </tr>
          <tr>
            <th style={{background:"lightgrey"}}>Implementing Entity</th><td colspan="3">{data.implementing_entity}</td>
          </tr>
          <tr>
            <th  style={{background:"lightgrey"}}>Contact Person</th><td>{data.contact_person}</td><th  style={{background:"lightgrey"}}>Contact Details</th><td>{data.contact_number}<br/>{data.email_address}</td>
          </tr>
          <tr>
            <th  style={{background:"lightgrey"}}>Province</th><td>{data.province}</td><th  style={{background:"lightgrey"}}>Municipality</th><td>{data.municipality}</td>
          </tr>
          <tr>
            <th  style={{background:"lightgrey"}}>Description</th><td colspan="3">{data.platform_description}</td>
          </tr>
          <tr>
            <th  style={{background:"lightgrey"}}>Current Status</th><td colspan="3">{data.status_update_per_intervention}</td>
          </tr>
          <tr>
            <th  style={{background:"lightgrey"}}>Challenges Encountered</th><td colspan="3">{data.challenges_encountered}</td>
          </tr>
        </table>
      </div>
      <button className='btn' onClick={downloadDocument}>Download Report</button>
    </div>
  );
};

export default WordDocumentGenerator;
