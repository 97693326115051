import { useEffect, useState } from "react";
import { apiEndpoint } from "./QUICKFUNCTIONS/endpoint";
import { _editData, addData, deleteData, fetchData } from "./QUICKFUNCTIONS/fetchData";
import TableComponent from "./TABLES/TableComponent";
import Header from "../Header";
import EditForm from "./FORMS/EditForm";
import InsertForm from "./FORMS/InsertForm";
import { Button } from "reactstrap";
import Notiflix from "notiflix";

export const CRUD=({canDelete=true,canEdit=true,addTitle,editTitle,params,columns,actions,dataPoint})=>{
    const [apilink,setApiLink]=useState("");
    const [data, setData] = useState([]);
    const [editData,setEditData]=useState([]);
    const [isEditing,setIsEditing]=useState(false);
    const [isAdding,setIsAdding]=useState(false);
    useEffect(()=>{setApiLink(apiEndpoint)},[apiEndpoint]);
    const fetchDataAndSetState = async () => {
        try {
                const result = await fetchData(apilink+"?_method=get&_condition="+params._condition+"&_table_name="+params._table_name);
                setData(result);
        } catch (error) {
                console.error("Error fetching data:", error);
            }
    };

    const editEntity=async (_edited)=>{
        await _editData(apiEndpoint,{..._edited,...params,_method:"update"});
        fetchDataAndSetState();
        setIsEditing(false);
    };


  
  const deleteEntity=async (params)=>{
    await deleteData(apiEndpoint,params);
    fetchDataAndSetState();
  };

   const addEntity= async(_data)=>{
    await addData(apiEndpoint,{..._data,...params,_method:"insert"});
    setIsAdding(false);
    fetchDataAndSetState();
  };


  useEffect(() => {
    fetchDataAndSetState();
  }, [apilink]);


  

  const handleRowDelete = async (row,tableRowIndex) => {
    Notiflix.Confirm.show("Confirm","Are you sure you want to delete this record?","Yes","No",()=>{try {
      const primaryKey = row.id;
      deleteEntity({...params,id: primaryKey,_method:"delete"});
      const updatedData = data.filter((_, index) => index !== tableRowIndex);
      setData(updatedData);
      
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  });
  };

  const handleRowEdit=(row,tableRowIndex)=>{
     setEditData(row);
     setIsEditing(true);
  };



  return (
    <div className="content">
      <Header/>
      <Button onClick={()=>{setIsAdding(true);}}>{addTitle}</Button>
      
      {data.length > 0 && (
        <TableComponent canEdit={canEdit} canDelete={canDelete} dataPoint={dataPoint} actions={actions} onRowDelete={handleRowDelete} onRowEdit={handleRowEdit} data={data} primaryKey="id" />
      )}
      
      {isEditing && editData &&
         <div style={{position:"fixed",padding:"20px",overflowY:"scroll",top:"0px",left:"0px",zIndex:"99999",width:"100vw",height:"100vh",background:"#f5f6fa",display:"flex",justifyContent:"center"}}>
         <div style={{position:"relative",boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.2)",borderRadius:"5px",width:"600px",maxWidth:"96%",padding:"20px",border:"0px solid #ccc",overflowY:"scroll",background:"white"}}>
             <font style={{position:"absolute",top:"10px",right:"10px",zIndex:"999",padding:"10px",width:"20px",height:"20px",border:".1px solid #ccc",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"100%",cursor:"pointer"}} onClick={()=>{setIsEditing(false)}}>&times;</font>
             <h1>{editTitle}</h1>
              <EditForm  columns={columns} onSubmit={(_edited)=>{editEntity(_edited)}} data={editData} />
        </div>
        </div>
       }

       {isAdding && 
           <div style={{position:"fixed",padding:"20px",overflowY:"scroll",top:"0px",left:"0px",zIndex:"99999",width:"100vw",height:"100vh",background:"#f5f6fa",display:"flex",justifyContent:"center"}}>
              <div style={{position:"relative",boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.2)",borderRadius:"5px",width:"600px",maxWidth:"96%",padding:"20px",border:"0px solid #ccc",overflowY:"scroll",background:"white"}}>
                  <font style={{position:"absolute",top:"10px",right:"10px",zIndex:"999",padding:"10px",width:"20px",height:"20px",border:".1px solid #ccc",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"100%",cursor:"pointer"}} onClick={()=>{setIsAdding(false)}}>&times;</font>
                  <h1>{addTitle}</h1>
                  <InsertForm  columns={columns} onSubmit={(_data)=>{addEntity(_data);}} />
              </div>
           </div>
       }
    </div>
  );

};