import { CRUD } from "../CRUD";
import { handleExportToCSV } from "../QUICKFUNCTIONS/Functions";
export const Allmentors=()=>{
    const addTitle="Add Mentor";
    const editTitle="Edit Mentor Info";
    const params={_table_name:"mentors",_condition:true};
    const columns = [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'surname', label: 'Surname', type: 'text' },
      { name: 'race', label: 'Race', type: 'dropdown',options:['Coloured', 'Indian', 'African', 'White', 'Chinese', 'Foreign African', 'Foreign Other', 'Other'] },
      { name: 'race_other_description', label: 'Specify Race', type: 'textarea',dependsOn:"race",ifIs:"Other" },
      { name: 'gender', label: 'Gender', type: 'dropdown', options: ["Male", "Female","Other"] },
      { name: 'email', label: 'Email', type: 'email' },
      { name: 'telephone_no', label: 'Telephone No', type: 'text' },
     { name: 'areas_of_expertise', label: 'Areas of Expertise', type: 'text' },
    ];
     const actions = [
      {
        name: "export_csv",
        label: 'Export to CSV',
        type: 'custom',
        render: (rowData,columns) => (
          <span style={{ color: "green", cursor: "pointer" }} onClick={handleExportToCSV(rowData,columns)}>
            Export to CSV
          </span>
        )
      }
    ];
     return  <CRUD actions={actions} params={params}  addTitle={addTitle} editTitle={editTitle} columns={columns}/>
   
   }