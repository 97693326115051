import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, Navigate, useLocation, HashRouter } from "react-router-dom"; // Import HashRouter
import PerfectScrollbar from "perfect-scrollbar";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "routes.js";
import logo from "assets/img/GalatticaLogo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { Alladministrators } from "views/QUICKUI/QUICKCOMPONENTS/Alladministrators";
import WordDocumentGenerator from "views/CreateDoc";

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = useRef(null);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  if (localStorage.getItem("token") == null) {
    //window.location.replace("/");
  }
  useEffect(() => {
    let ps;
    const isWin = navigator.platform.indexOf("Win") > -1;

    if (isWin && localStorage.getItem("token") !== null) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, { suppressScrollX: true });

      const tables = document.querySelectorAll(".table-responsive");
      tables.forEach((table) => {
        ps = new PerfectScrollbar(table);
      });

      return () => {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      };
    }
  }, []);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      const tables = document.querySelectorAll(".table-responsive");
      tables.forEach((table) => {
        new PerfectScrollbar(table);
      });
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpened(!sidebarOpened);
  };

  const getRoutes = (routes) => {
    return routes.flatMap((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.children) {
          const childRoutes = prop.children.map((child, childKey) => (
            <Route path={child.path} element={child.component} key={`${key}-${childKey}`} exact />
          ));
          return [
            <Route path={prop.path} element={prop.component} key={key} exact />,
            ...childRoutes,
          ];
        } else {
          return <Route path={prop.path} element={prop.component} key={key} exact />;
        }
      } else {
        return null;
      }
    });
  };

  const updatedRoutes = [
    ...routes,
    {
      path: "/administrators",
      name: "Administrators",
      rtlName: "",
      icon: "",
      component: <Alladministrators/>,
      layout: "/admin",
    },
    {
      path: "/worddoc",
      name: "Report",
      rtlName: "",
      icon: "",
      component: <WordDocumentGenerator/>,
      layout: "/admin",
    }
  ];

  const getBrandText = (path) => {
    /*for (let i = 0; i < updatedRoutes.length; i++) {
      if (location.pathname.indexOf(updatedRoutes[i].layout + updatedRoutes[i].path) !== -1) {
        return updatedRoutes[i].name;
      }
    }*/
    return "Galattica";
  };

  return (
    <>
      {localStorage.getItem("token") && (
        <BackgroundColorContext.Consumer>
          {({ color, changeColor }) => (
           
              <div className="wrapper">
                <Sidebar
                  routes={routes}
                  logo={{
                    outterLink: "https://galattica.co.za",
                    text: "Galattica",
                    imgSrc: logo,
                  }}
                  toggleSidebar={toggleSidebar}
                />
                <div className="main-panel" ref={mainPanelRef} data={color}>
                  <AdminNavbar
                    brandText={localStorage.getItem("name")+" "+localStorage.getItem("surname")}
                    toggleSidebar={toggleSidebar}
                    sidebarOpened={sidebarOpened}
                  />
                  <Routes>
                    {getRoutes(updatedRoutes)}
                  </Routes>
                </div>
                <FixedPlugin bgColor={color} handleBgClick={changeColor} />
              </div>
          
          )}
        </BackgroundColorContext.Consumer>
      )}
    </>
  );
}

export default Admin;
