import React, { useState } from "react";
import BgImage from "assets/img/loginbg.png";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import UserIcon from "./QUICKUI/Icons/UserIcon";
import PasswordIcon from "./QUICKUI/Icons/PasswordIcon";
import { generalApiEndpoint } from "./QUICKUI/QUICKFUNCTIONS/endpoint";
function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

 
  if (localStorage.getItem("token") !== null) {
    window.location.replace("/#/admin/landingpage");
  }

  const handleLogin = () => {
    // Make an API call to your login endpoint
    fetch(generalApiEndpoint + "/login/login.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        useremail: username,
        userpassword: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming your PHP backend returns a JSON object with "token", "name", "surname", and "email" properties on successful login
        localStorage.setItem("token", data.token);
        localStorage.setItem("name", data.name);
        localStorage.setItem("surname", data.surname);
        localStorage.setItem("email", data.email);
        window.location.replace("/#/admin/landingpage");
      })
      .catch((error) => {
        console.error("Error during login:", error);
        alert("Wrong login details");
      });
  };
  

  return (
    <div className="container">
      <div className="card rounded" style={{ padding: "10px", marginTop: "12.5vh", height: "75vh", backgroundSize: "cover", position: "relative", borderRadius: "0", backgroundPosition: "top", backgroundImage: `URL(${BgImage})`, display: "flex", justifyContent: "center", alignItems: "center" }}>

        <Form style={{ maxWidth: "500px", margin: "auto", padding: "10px", padding: "25px", border: "4px solid #e8f0fe", borderRadius: "20px", paddingLeft: "50px", paddingRight: "50px" }} className="justify-content-center text-center card">
          <h2 className="text-center" style={{ marginTop: "15px" }}>RISP Online Reporting Platform</h2>
          <FormGroup>
            <table style={{ width: "98%" }}>
              <tbody>
                <tr>
                  <td><UserIcon /></td>
                  <td><Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="off"
                  />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>
          <FormGroup>
            <table style={{ width: "98%" }}>
              <tbody>
                <tr>
                  <td><PasswordIcon /></td>
                  <td><Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>
          <Button style={{ width: "150px", margin: "auto", background: "linear-gradient:(45deg,black,#994828)" }} onClick={handleLogin}>
            Login
          </Button>
        </Form>

      </div>
    </div>
  );
}

export default Login;
