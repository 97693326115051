import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {BackgroundColorContext} from "contexts/BackgroundColorContext";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import LogoutIcon from "views/Icons/LogoutIcon";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);

  const [dropdownStates, setDropdownStates] = useState({});

  const toggleDropdown = (key) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const isDropdownOpen = (key) => dropdownStates[key] || false;

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });


  const { routes, rtlActive, logo } = props;
  let logoImg = null;
  let logoText = null;

  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
       <></>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
          style={{fontWeight:"600"}}
        >
          RISP Online<br/>Reporting Platform
        </Link>
      );
    } else {
      logoImg = (
       <></>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {"RISP Online Reporting Platform"}
        </Link>
      );
    }
  }

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" style={{background:"#001d34"}}>
          <div className="sidebar-wrapper" style={{paddingLeft:"15px"}} ref={sidebarRef}>
            {logoImg !== null || logoText !== null ? (
              <div className="logo">
                {logoImg}
                {logoText}
              </div>
            ) : null}

            <Nav>
              {routes.map((prop, key) => {
                if (prop.redirect) return null;

                if (prop.children) {
                  return (
                    <Dropdown
                      key={key}
                      className="nav-link"
                      isOpen={isDropdownOpen(key)}
                      toggle={() => toggleDropdown(key)}
                      style={{padding:"0px",margin:"0px",
                      textTransform: "uppercase",
                      cursor: "pointer",
                      fontSize: "0.62475rem",
                      padding: "10px 8px",
                      lineHeight: "1.625rem",
                      fontWeight:"300"
                    }}
                    >
                   
                        <DropdownToggle className="nav-link" style={{cursor:"pointer",color:"white",    textTransform: "uppercase",
                      cursor: "pointer",
                      fontSize: "14px",
                      padding: "10px 8px",
                      lineHeight: "1.625rem",
                      marginLeft:"0px",
                      paddingLeft:"0px",
                      fontWeight:"300"
                      }} tag="font">
                        {rtlActive ? prop.rtlName : prop.name} ▼
                        </DropdownToggle>
                
                      <DropdownMenu>
                        {prop.children.map((subitem, subkey) => (
                          <DropdownItem
                            key={subkey}
                            onClick={props.toggleSidebar}
                          >
                            <NavLink to={subitem.layout + subitem.path}>
                              {rtlActive ? subitem.rtlName : subitem.name}
                            </NavLink>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  );
                } else {
                  return (
                   
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        onClick={props.toggleSidebar}
                        style={{color:"white",    textTransform: "uppercase",
                        cursor: "pointer",
                        fontSize: "14px",
                        padding: "10px 8px",
                        lineHeight: "1.625rem",
                        fontWeight:"300"
                      }}
                      >
                        {/*<i className={prop.icon} />*/}
                        {prop.name}
                      </NavLink>
                   
                  );
                }
              })}

      
            </Nav>
            <Link style={{textTransform:"uppercase",fontWeight:"600",color:"#df5e11e6",bottom:"10px",left:"25px"}}
            
            onClick={()=>{
              localStorage.removeItem("token");
            }}
            to="/#/login"><LogoutIcon/> Log out</Link>
          </div>
       
        </div>
      )}
      
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
