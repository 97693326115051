// InputDropdown.js
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const InputDropdown = ({ column, formData, handleChange, triggeredOptions }) => (
  <FormGroup>
    <Label for={column.name}>{column.label || column.name}</Label>
    <Input
      type="select"
      id={column.name}
      name={column.name}
      value={formData[column.name] || ''}
      onChange={(e) => handleChange(e, column.name)}
    >
      <option value="">Select {column.label || column.name}</option>
      {triggeredOptions[column.name] ? (
        triggeredOptions[column.name].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      ) : (
        column.options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))
      )}
    </Input>
  </FormGroup>
);

export default InputDropdown;
