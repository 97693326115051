import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ProviderIcon from "./QUICKUI/Icons/ProviderIcon";
import MentorIcon from "./QUICKUI/Icons/MentorIcon";
import CommitteeIcon from "./QUICKUI/Icons/CommiteeIcon";
import PortfolioIcon from "./QUICKUI/Icons/PortfolioIcon";
import Header from "./Header";
import { Card, CardBody } from "reactstrap";
import { Bar, Line, Pie } from "react-chartjs-2";
import { chartExample1 } from "variables/charts";
import BeneficiaryIcon from "./QUICKUI/Icons/BeneficiaryIcon";
import CapacityIcon from "./QUICKUI/Icons/CapacityIcon";
const LandingPage = () => {
  const [bigChartData, setbigChartData] = React.useState("data");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (token == null) {
    // Redirect to the login page
    //navigate("/login");
    //return null; // You can also return a loading spinner or message if needed
  }

  return (
    <div className="content">
      <center>
        
        <div className="card">

        <Header/>
        <br/>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
                <Link to="../beneficiaries"><BeneficiaryIcon/>
                  <h5>Beneficiary Database</h5>
                </Link>
              </div>
              <div className="col-lg-4">
                <Link to="../mentors">
                    <MentorIcon/>
                    <h5>Mentor Database</h5>
                </Link>
                </div>
                <div className="col-lg-4">
                <Link to="../capacitybuildingprogrammes">
                    <CapacityIcon/>
                    <h5>Capacity Building Programmes</h5>
                </Link>
            </div>
          </div>
        </div>

             </div>
      </center>
    </div>
  );
};

export default LandingPage;
