import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import DocUploadIcon from '../Icons/DocUploadIcon';

const InputPdf = ({ column, chosenImagesMap, handleChange }) => (
  <center style={{ border: '.1px solid #ccc', borderRadius: '5px', padding: '10px',marginBottom:"10px",marginTop:"10px" }}>
    <br />
    <Label for={column.name}><DocUploadIcon/>  {column.label || column.name}</Label>
    <Input
      type="file"
      id={column.name}
      name={column.name}
      onChange={(e) => handleChange(e, column.name)}
      multiple // Allow multiple file selection
      style={{visibility:"hidden"}}
    />
    {chosenImagesMap[column.name] && (
      <div>
        <p>Uploaded PDFs:</p>
        {chosenImagesMap[column.name].map((pdf, index) => (
          <div key={index}>
            <span>{pdf.name}</span>
          </div>
        ))}
      </div>
    )}
  </center>
);

export default InputPdf;
