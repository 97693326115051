import Notiflix from "notiflix";

const getToken = () => {
  return localStorage.getItem("token");
};

const showNotification = (type, message) => {
  Notiflix.Notify[type](message);
};

export const fetchData = async (apiEndpoint) => {
  try {
    const response = await fetch(apiEndpoint + "&token=" + getToken());
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    showNotification("failure", "Error fetching data");
    throw error;
  }
};

export const deleteData = async (apiEndpoint, params = {}) => {
  try {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...params, token: getToken() }),
    };

    const response = await fetch(apiEndpoint, requestOptions);

    if (response.ok) {
      const data = await response.json();
      showNotification("success", "Data deleted successfully");
      return data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting data:', error);
    showNotification("failure", "Error deleting data");
    throw error;
  }
};

export const addData = async (apiEndpoint, params) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...params, token: getToken() }),
    };

    const response = await fetch(apiEndpoint, requestOptions);

    if (response.ok) {
      const data = await response.json();
      showNotification("success", "Data added successfully");
      return data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error adding data:', error);
    showNotification("failure", "Error adding data");
    throw error;
  }
};

export const _editData = async (apiEndpoint, params = {}) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...params, token: getToken() }),
    };

    const response = await fetch(apiEndpoint, requestOptions);

    if (response.ok) {
      const data = await response.json();
      showNotification("success", "Data edited successfully");
      return data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error editing data:', error);
    showNotification("failure", "Error editing data");
    throw error;
  }
};
