import React, { useEffect, useState } from 'react';
import { Form, Button } from 'reactstrap';
import InputText from '../INPUTS/InputText';
import InputCheckbox from '../INPUTS/InputCheckbox';
import InputTextarea from '../INPUTS/InputTextarea';
import InputDropdown from '../INPUTS/InputDropdown';
import InputRadio from '../INPUTS/InputRadio';
import { fetchData } from '../QUICKFUNCTIONS/fetchData';
import InputEmail from '../INPUTS/InputEmail';
import InputPdf from '../INPUTS/InputPdf';
import InputPhoto from '../INPUTS/InputPhoto';
// Import other input components

const EditForm = ({ columns, onSubmit,data }) => {
  const [formData, setFormData] = useState(data);
  const [triggeredOptions, setTriggeredOptions] = useState({});
  const [chosenImagesMap, setChosenImagesMap] = useState({});

  const handleChange = (e, columnName) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.type === 'file') {
      const newImages = Array.from(e.target.files).filter((file) => true);

      if (newImages.length > 0) {
        setChosenImagesMap((prevMap) => ({
          ...prevMap,
          [columnName]: [...(prevMap[columnName] || []), ...newImages],
        }));
      } else {
        console.log('No valid image files selected.');
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [columnName]: value,
    }));
  };

  useEffect(() => {
    setTriggeredOptions({});
    const fetchDataAndSetOptions = async (column) => {
      const options = await fetchData(column.fetchurl + '?' + column.trigger + '=' + formData[column.trigger]);
      setTriggeredOptions((prevOptions) => ({
        ...prevOptions,
        [column.name]: options,
      }));
    };

    const fetchDataForColumns = async () => {
      await Promise.all(
        columns.map(async (column) => {
          if (column.trigger) {
            await fetchDataAndSetOptions(column);
          }
        })
      );
    };

    fetchDataForColumns();
  }, [formData, columns]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {columns.map((column) => {
        switch (column.type) {
          case 'text':
            return <InputText key={column.name} setFormData={setFormData} column={column} formData={formData} handleChange={handleChange} />;
          case 'check':
            return <InputCheckbox key={column.name} column={column} formData={formData} handleChange={handleChange} />;
          case 'email':
            return <InputEmail key={column.name} column={column} formData={formData} handleChange={handleChange} />;
          case 'textarea':
            return <InputTextarea key={column.name} column={column} formData={formData} handleChange={handleChange} />;
          case 'dropdown': return <InputDropdown key={column.name} column={column} formData={formData} handleChange={handleChange} triggeredOptions={triggeredOptions} />;
          case 'radio':
            return <InputRadio key={column.name} column={column} formData={formData} handleChange={handleChange} />;
          case 'pdf':
            return <InputPdf chosenImagesMap={chosenImagesMap} key={column.name} column={column} formData={formData} handleChange={handleChange} />;
          case 'photo':
            return <InputPhoto key={column.name} chosenImagesMap={chosenImagesMap} column={column} formData={formData} handleChange={handleChange} />;
            default:
            return null;
        }
      })}
      <Button type="submit" color="primary">
        Submit
      </Button>
    </Form>
  );
};

export default EditForm;
