import React from 'react';

const BeneficiaryIcon = () => {
  return (
    <div style={{ width: '100px', height: '100px' }}>
      <svg
        fill="#000000"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0 0 47.001 47.001"
        xmlSpace="preserve"
      >
        {/* Paste the rest of the SVG code here */}
        <g>
	<g id="Layer_1_69_">
		<g>
			<circle cx="9.415" cy="8.898" r="3.515"/>
			<path d="M14.237,29.71c-0.432-0.039-0.859-0.133-1.267-0.289c-1.171-0.451-2.097-1.334-2.606-2.484
				c-0.509-1.148-0.54-2.43-0.086-3.602l3.761-9.735c-0.257-0.119-0.532-0.204-0.827-0.204h-1.931c-0.546,0-1.019,0.237-1.375,0.594
				H8.923c-0.355-0.356-0.831-0.594-1.375-0.594H5.616c-0.815,0-1.552,0.502-1.847,1.267l-3.634,9.413
				c-0.396,1.02,0.111,2.168,1.132,2.562c1.019,0.395,2.167-0.112,2.561-1.135L5.567,21v5.296L1.912,39.091
				c-0.302,1.056,0.309,2.148,1.359,2.451c0.183,0.051,0.366,0.074,0.547,0.074c0.861,0,1.653-0.564,1.901-1.434l3.695-12.932
				l3.473,12.156l2.06-7.212L14.237,29.71z"/>
			<path d="M37.587,12.415c1.938,0,3.516-1.573,3.516-3.515s-1.577-3.516-3.516-3.516c-1.94,0-3.516,1.574-3.516,3.516
				S35.646,12.415,37.587,12.415z"/>
			<path d="M46.866,24.075l-3.635-9.413c-0.295-0.764-1.03-1.267-1.847-1.267h-1.933c-0.543,0-1.02,0.237-1.374,0.594h-0.983
				c-0.356-0.356-0.829-0.594-1.375-0.594h-1.931c-0.295,0-0.57,0.084-0.827,0.204l3.761,9.735c0.454,1.172,0.423,2.453-0.086,3.602
				c-0.51,1.15-1.436,2.033-2.608,2.486c-0.405,0.156-0.831,0.236-1.262,0.275l-0.714,2.496l2.062,7.215l3.473-12.158l3.694,12.933
				c0.249,0.868,1.04,1.437,1.901,1.437c0.181,0,0.364-0.027,0.547-0.078c1.051-0.301,1.662-1.396,1.358-2.449l-3.655-12.797V21
				l1.74,4.502c0.394,1.022,1.542,1.529,2.561,1.136C46.755,26.243,47.262,25.095,46.866,24.075z"/>
			<path d="M23.501,10.327c2.304,0,4.178-1.87,4.178-4.177c0-2.309-1.874-4.178-4.178-4.178c-2.304,0-4.178,1.87-4.178,4.178
				C19.323,8.457,21.197,10.327,23.501,10.327z"/>
			<path d="M28.071,26.821v-6.293l2.067,5.352c0.468,1.213,1.832,1.814,3.042,1.347c1.213-0.468,1.816-1.829,1.348-3.042
				l-4.32-11.186c-0.35-0.907-1.224-1.505-2.194-1.505h-2.295c-0.646,0-1.21,0.282-1.633,0.706l1.095,8.042l-1.68,1.425l-1.68-1.425
				l1.095-8.042c-0.423-0.424-0.986-0.706-1.633-0.706h-2.295c-0.972,0-1.846,0.598-2.194,1.505l-4.321,11.186
				c-0.469,1.213,0.135,2.576,1.348,3.042c1.211,0.469,2.574-0.134,3.042-1.347l2.067-5.352v6.293l-4.344,15.207
				c-0.357,1.25,0.367,2.553,1.616,2.909c0.216,0.063,0.435,0.092,0.647,0.092c1.024,0,1.967-0.676,2.262-1.706l4.39-15.367
				l4.39,15.367c0.295,1.03,1.237,1.706,2.262,1.706c0.214,0,0.433-0.028,0.647-0.092c1.249-0.356,1.974-1.659,1.616-2.909
				L28.071,26.821z"/>
		</g>
	</g>
</g>
      </svg>
    </div>
  );
};

export default BeneficiaryIcon;
