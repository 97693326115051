import React from 'react';

const DocUploadIcon = () => {
  return (
    <svg fill="#000000" height="30px" width="30px" version="1.1" id="XMLID_84_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve">
      <g id="document-upload">
        <g>
          <path d="M22,24H2V0h14.4L22,5.6V24z M4,22h16V8h-6V2H4V22z M16,6h3.6L16,2.4V6z M13,20h-2v-6.6l-2.3,2.3l-1.4-1.4L12,9.6l4.7,4.7 l-1.4,1.4L13,13.4V20z"/>
        </g>
      </g>
    </svg> 
  );
};

export default DocUploadIcon;
