// InputCheckbox.js
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const InputCheckbox = ({ column, formData, handleChange }) => (
  <FormGroup>
    <Label for={column.name}>{column.label || column.name}</Label>
    <Input
      type="checkbox"
      checked={formData[column.name]}
      id={column.name}
      name={column.name}
      value={formData[column.name] || ''}
      onChange={(e) => handleChange(e, column.name)}
      style={{ marginLeft: '15px' }}
    />
  </FormGroup>
);

export default InputCheckbox;
