// InputRadio.js
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const InputRadio = ({ column, formData, handleChange }) => (
  <div style={{ marginLeft: '20px' }}>
    {column.options.map((option) => (
      <FormGroup check key={option}>
        <Input
          type="radio"
          id={option}
          name={column.name}
          value={option}
          checked={formData[column.name] === option}
          onChange={(e) => handleChange(e, column.name)}
        />
        <Label check htmlFor={option}>
          {option}
        </Label>
      </FormGroup>
    ))}
  </div>
);

export default InputRadio;
