// TableComponent.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import './CSS/TableComponent.css';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';
import GlobalSearchInput from './GlobalSearchInput';
import SimplePieChart from './SimplePieChart';

  const TableComponent = ({ canDelete,canEdit,data = [], onRowEdit, onRowDelete, actions, dataPoint }) => {
  const [originalData, setOriginalData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [columnFilters, setColumnFilters] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [clickedColumn, setClickedColumn] = useState(null);
  const [staticColumns, setStaticColumns] = useState(null);

  // Initialize originalData and updatedData from props data
  useEffect(() => {
    setOriginalData([...data]);
    setUpdatedData([...data]);
    setStaticColumns(Object.keys(data[0]));
  }, [data]);

  const calculatePieChartData = () => {
    const dataPointValues = originalData.map((item) => item[dataPoint]);
    const distinctDataValues = [...new Set(dataPointValues)];
    const labels = distinctDataValues.map((value) => String(value));
    const dataCounts = distinctDataValues.map((value) => dataPointValues.filter((v) => v === value).length);
    const data = dataCounts.map((count) => (count / dataPointValues.length) * 100);
    return {
      data: data,
      labels: labels,
    };
  };

  const renderTableHeaders = () => {
    if (updatedData.length === 0) {
      return null;
    }

    const headers = Object.keys(updatedData[0]);

    return (
      <>
        <th style={{ border: '.1px solid #ccc', background: 'inherit' }}>ACTION</th>
        {headers.map((header, index) => (
          <TableHeaderCell
           formatHeader={formatHeader}
            key={index}
            header={header}
            columnFilters={columnFilters}
            handleColumnFilterChange={handleColumnFilterChange}
            getDistinctColumnValues={getDistinctColumnValues}
            clickedColumn={clickedColumn}
            handleColumnClick={handleColumnClick}
          />
        ))}
      </>
    );
  };

  const handleColumnClick = (columnIndex) => {
    setClickedColumn((prevClickedColumn) =>
      prevClickedColumn === columnIndex ? null : columnIndex
    );
  };

  const handleColumnFilterChange = (column, value) => {
    try {
      setColumnFilters({
        ...columnFilters,
        [column]: value,
      });
    } catch (error) {
      console.error('Error in handleColumnFilterChange:', error);
    }
  };

  const formatHeader = (header) => {
    return header.replace(/_/g, ' ').toUpperCase();
  };

  const getDistinctColumnValues = (column) => {
    return [...new Set(originalData.map((item) => item[column]))];
  };

  const applyFilters = () => {
    let filteredData = originalData;

    // Apply column filters
    Object.keys(columnFilters).forEach((column) => {
      const filterValue = columnFilters[column];
      if (filterValue) {
        filteredData = filteredData.filter((item) => String(item[column]) === filterValue);
      }
    });

    // Apply global search
    if (searchValue.trim() !== '') {
      const searchKeywords = searchValue.toLowerCase().split(' ');
      filteredData = filteredData.filter((item) => {
        return searchKeywords.every((keyword) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(keyword)
          )
        );
      });
    }

    return filteredData;
  };

  const renderTableRows = () => {
    const filteredData = applyFilters();

    return filteredData.map((item, rowIndex) => (
      <TableRow
      canDelete={canDelete}
      canEdit={canEdit}
      clickedColumn={clickedColumn}
      staticColumns={staticColumns}
      actions={actions}
      setHighlightedRow={setHighlightedRow}
        key={rowIndex}
        item={item}
        rowIndex={rowIndex}
        clickedRow={clickedRow}
        highlightedRow={highlightedRow}
        handleDeleteClick={handleDeleteClick}
        handleEditClick={handleEditClick}
        handleCustomActionClick={handleCustomActionClick}
        handleArrowClick={handleArrowClick}
      />
    ));
  };

  const handleDeleteClick = (row, rowIndex) => {
    onRowDelete(row, rowIndex);
  };

  const handleEditClick = (row, rowIndex) => {
    if (onRowEdit) {
      onRowEdit(row, rowIndex);
    }
  };

  const handleCustomActionClick = (action, row, rowIndex) => {
    if (action.onClick) {
      action.onClick(row, rowIndex);
    }
  };

  const handleArrowClick = (rowIndex) => {
    setClickedRow((prevClickedRow) => (prevClickedRow === rowIndex ? null : rowIndex));
    setHighlightedRow(null); // Clear the highlighted row
    console.log(`Row ${rowIndex} arrow clicked!`);
  };

  return (
    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
      {updatedData.length > 0 && (
        <div>
          <table style={{ width: '100%', maxWidth: '100%' }}>
            <tr>
              <td style={{ width: '50%' }}>
                <GlobalSearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
              </td>
              <td>
                {dataPoint && <SimplePieChart data={calculatePieChartData().data} labels={calculatePieChartData().labels} />}
              </td>
            </tr>
          </table>
          <div style={{ width: '100%', maxWidth: '100%', overflow: 'scroll' }}>
            <Table style={{ marginTop: '15px' }} className="custom-table">
              <thead>
                <tr>{renderTableHeaders()}</tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

TableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowEdit: PropTypes.func,
  onRowDelete: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  dataPoint: PropTypes.string.isRequired,
};

export default TableComponent;
